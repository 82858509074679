/* Header Styling */
.header {
  background-color: #0B132B;
  padding: 20px 5%; /* Flexible padding for responsiveness */
  color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 4px solid #17C3B2;
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 100vw; /* Ensure it doesn’t exceed viewport width */
  box-sizing: border-box; /* Include padding and border in the element’s total width and height */
  z-index: 1000;
  transition: all 0.3s ease;
  background-clip: padding-box; /* Prevents any box-shadow or border overlap */
}

.header-scrolled {
  padding: 10px 5%; /* Consistent with non-scrolled header */
  border-bottom-width: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Name Title Styling */
.name-title {
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
}

.header-scrolled .name-title {
  flex-direction: row;
  align-items: center;
}

.name-title h1 {
  margin: 0;
  font-size: 2.5em;
  color: #5C7AFF;
  transition: font-size 0.3s ease;
}

.name-title h2 {
  margin: 5px 0 0 0;
  color: #17C3B2;
  font-size: 1.5em;
  transition: margin 0.3s ease, font-size 0.3s ease;
}


.header-scrolled .name-title h1 {
  font-size: 1.8em; /* Smaller font size when scrolled */
}

.header-scrolled .name-title h2 {
  margin-left: 15px;
  font-size: 1.2em; /* Adjust font size when scrolled */
}

/* Contact Info Styling */
.contact-info {
  display: flex;
  gap: 10px; /* Reduced gap for better responsiveness */
  align-items: center;
}

.contact-info .icon {
  color: #5C7AFF;
  font-size: 1.5em;
}

.contact-info .icon:hover {
  color: #17C3B2; /* Change color on hover */
}

.contact-info a {
  color: #5C7AFF;
  text-decoration: none;
}

.contact-info a:hover {
  color: #17C3B2; /* Change color on hover */
}

/* Button Styling */
.header-button {
  padding: 10px 20px;
  background-color: #17C3B2;
  color: #0B132B;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 10px;
}

.header-button:hover {
  background-color: #5C7AFF; /* Change button color on hover */
}

/* Header Content */
.header-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Media Queries for Responsiveness */
@media (max-width: 800px) {
  .header {
    padding: 15px 5%; /* Adjust padding */
  }

  .name-title h1 {
    font-size: 2em; /* Reduce font size on smaller screens */
  }

  .name-title h2 {
    font-size: 1.3em; /* Reduce font size on smaller screens */
  }

  .contact-info {
    gap: 5px; /* Adjust gap */
  }

  .header-button {
    font-size: 0.9em; /* Adjust button size */
    padding: 8px 16px; /* Adjust padding */
  }

  .header-scrolled .name-title h1 {
    font-size: 1.8em; /* Smaller font size when scrolled */
  }

  .header-scrolled .name-title h2 {
    display: none; /* Remove h2 when scrolled */
  }
}

@media (max-width: 480px) {
  .header {
    padding: 10px 5%; /* Further reduce padding for small screens */
    flex-direction: column; /* Stack elements vertically */
    align-items: flex-start;
    box-shadow: none; /* Remove shadow to prevent the line */
  }

  .name-title h1 {
    font-size: 1.8em; /* Smaller font size for small screens */
  }

  .name-title h2 {
    font-size: 1.2em; /* Smaller font size for small screens */
  }

  .contact-info {
    flex-direction: row; /* Stack contact info vertically */
    gap: 5px;
    margin-left: -10px; /* Move contact info slightly to the left */
  }

  .header-button {
    width: 60px; 
    font-size: 0.6em; /* Reduce font size */
    padding: 8px;
    text-align: center;
  }

  .header-scrolled .name-title h1 {
    font-size: 1.3em; /* Smaller font size when scrolled */
  }
  
  .header-scrolled .name-title h2 {
    display: none; /* Remove h2 when scrolled */
  }
}
