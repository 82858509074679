
.certifications-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 15px 20px;
}

.certification-item {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 1300px;
  cursor: pointer;
  overflow: hidden;
  transition: max-height 0.3s ease-out, box-shadow 0.3s;
  position: relative;
}

.certification-item.expanded {
  max-height: 500px; 
}

.certification-item .certification-header {
  padding-left: 25px;
  padding-top: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0B132B;
  color: #5C7AFF;
  font-size: 1.2rem;
}

.certification-item .expand-icon {
  font-size: 1.5rem;
  padding-right: 15px;
  padding-bottom: 5px;
}

.certification-item .certification-details {
  background-color: #e9e9e9;
  padding-left: 15px;
  padding-top: 7px;
  color: #0B132B;
  overflow: hidden;
}

.certification-details p{
  margin-bottom: 5px;
}
@media (max-width: 768px) {
  .certification-item {
    width: 90%;
  }
}
