
.skills-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  margin-top: 30px;
}

/* Skill Card */
.skill-card {
  width: 300px;
  height: 300px;
  perspective: 1000px;
}

.card-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.8s;
}

.skill-card:hover .card-inner {
  transform: rotateY(180deg);
}

/* Card Faces */
.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  box-sizing: border-box;
}

.card-front {
  background-color: #0B132B;
  color: #5C7AFF;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}

.card-back {
  background-color: #fff;
  color: #0B132B;
  transform: rotateY(180deg);
  padding: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

/* Skills List */
.skills-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.skill-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.skill-item span {
  font-size: 1rem;
  font-weight: 500;
}

/* Progress Bar */
.progress-bar {
  width: 60%;
  height: 8px;
  background-color: #bbb9b9 !important;
  border-radius: 5px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background-color: #17C3B2 !important;
  border-radius: 5px;
}

/* Responsive Design for Smaller Screens */
@media (max-width: 768px) {
  .skill-card {
    width: 250px;
    height: 250px;
  }

  .card-front,
  .card-back {
    font-size: 1.2rem;
  }

  .progress-bar {
    width: 50%;
  }
}

@media (max-width: 480px) {
  .skill-card {
    width: 200px;
    height: 200px;
  }

  .card-front,
  .card-back {
    font-size: 1rem;
  }

  .progress-bar {
    width: 40%;
  }

  .skill-item span {
    font-size: 0.9rem;
  }
}
