.projects-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 30px;
  margin-top: 25px;
}

.project-card {
  background: #0B132B;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Distributes space between the content */
}

.project-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.project-content {
  padding: 20px;
  flex-grow: 1; /* Allows the content to grow and take available space */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensure content is spread evenly */
}

.project-content h3 {
  font-size: 1.7rem;
  color: #5C7AFF;
  margin-bottom: 15px;
  position: relative;
  text-align: center;
}

.project-content h3::after {
  content: '';
  display: block;
  width: 50px;
  height: 3px;
  background-color: #17C3B2;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}

.project-content p {
  font-size: 1rem;
  color: #d6d6d6;
  margin-bottom: 20px;
  flex-grow: 1; /* Allow the description to take available space */
}

.project-links {
  display: flex;
  justify-content: space-between;
  align-items: flex-end; /* Align the links at the bottom */
}

.project-links a {
  display: inline-flex;
  align-items: center;
  color: #17C3B2;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s;
}

.project-links a:hover {
  color: #5C7AFF;
}

.project-links a svg {
  margin-right: 8px;
}

.tech-stack {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 25px;
}

.tech-icon {
  color: #5C7AFF; /* Adjust the color to match your theme */
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tech-icon img {
  width: 34px; 
  height: 34px; 
}

.tooltip-icon {
  width: 20px;
  height: 20px;
}

.tooltip-text {
  visibility: hidden;
  width: auto;
  background-color: #5C7AFF;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the icon */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  white-space: nowrap;
}

.tech-icon:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
