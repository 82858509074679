
/* Mobile Timeline Marker */
.mobile-timeline-marker {
  width: 45px; /* Smaller size for mobile */
  height: 45px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 2px #fff;
}
.mobile-timeline-marker .icon {
  width: 33px;
  height: 33px;
}