/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the modal overlay is above other content */
}

/* Modal Content */
.modal-content {
  background: #FFFFFF !important;
  color: #0B132B;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 90%; /* Adjust to fit smaller screens */
  width: 100%;
  position: relative;
  z-index: 1001; /* Ensure the modal content is above the overlay */
  overflow: auto; /* Handle overflow if content is too large */
  border-radius: 15px !important;
}

/* Modal Title */
.modal-content h3 {
  color: #5C7AFF;
  text-align: center;
  font-size: 1.5em; /* Adjust font size for better readability */
}

/* Close Button */
.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #17C3B2;
  color: #FFFFFF;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  cursor: pointer;
  z-index: 1002; /* Ensure the close button is above everything */
}

/* Close Button Hover */
.modal-close:hover {
  background: #5C7AFF;
}

/* Details List */
.modal-details-list {
  list-style-type: disc;
  margin: 20px 0;
  padding-left: 20px;
}

/* Detail Item */
.modal-detail-item {
  margin-bottom: 10px;
  font-size: 1em;
  line-height: 1.5;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .modal-content {
    max-width: 95%; /* Increase width for mobile devices */
    padding: 15px; /* Reduce padding */
  }

  .modal-content h3 {
    font-size: 1.2em; /* Adjust font size for smaller screens */
  }

  .modal-close {
    width: 25px;
    height: 25px;
    font-size: 1em;
    top: 5px;
    right: 5px;
  }

  .modal-details-list {
    padding-left: 15px; /* Reduce padding for mobile devices */
  }

  .modal-detail-item {
    font-size: 0.9em; /* Reduce font size */
  }
}

@media (max-width: 480px) {
  .modal-content {
    padding: 10px; /* Further reduce padding */
  }

  .modal-content h3 {
    padding-top: 10px;
    font-size: 1em; /* Further adjust font size */
  }

  .modal-close {
    width: 20px;
    height: 20px;
    font-size: 0.8em;
    top: 20px;
    right: 15px;
  }

  .modal-details-list {
    padding-left: 20px; /* Further reduce padding */
    padding-right: 10px;
  }

  .modal-detail-item {
    font-size: 0.8em; /* Further reduce font size */
  }
}
