.App {
  color: #0B132B;
  line-height: 1.6;
  background-color: #ddd;
  font-family: 'Verdana', sans-serif;
  width: 100%;
}

.content {
  padding: 20px 40px;
}

h2 {
  color: #5C7AFF;
  margin-bottom: 10px;
}

section {
  padding: 20px 0;
  border-bottom: 1px solid #ddd;
}

.portfolio-section {
  background: linear-gradient(135deg, #5C7AFF, #17C3B2);
  color: #0B132B;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-top: 75px;
}

.portfolio-section-header {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  justify-content: center;
}

.portfolio-section-subheader {
  color: #0B132B;
  font-size: 0.9rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  padding: 3px 10px 4px 10px;
  border-radius: 5px;
  text-align: center;
}
