/* Timeline Container */
.timeline-container {
  display: flex;
  overflow-x: auto; /* Allows horizontal scrolling if needed */
  padding: 20px;
  border-radius: 8px;
  padding-bottom: 110px;
  width: 100%; /* Ensure the container takes full width */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

/* Timeline */
.timeline {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%; /* Make timeline stretch to fit the container */
}

/* Timeline Item */
.timeline-item {
  position: relative;
  display: flex;
  flex-direction: column; /* Align marker and tooltip vertically */
  align-items: center;
  flex: 1; /* Allow items to grow and shrink equally */
  margin: 0 15px; /* Space on both sides of the item */
}

/* Timeline Marker */
.timeline-marker {
  width: 60px; /* Size to fit the icon */
  height: 60px; /* Size to fit the icon */
  background: #fff;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 2px #fff;
}

/* Timeline Tooltip */
.timeline-tooltip {
  position: absolute;
  top: 120%; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  background: #0B132B;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  z-index: 2;
  width: 250px;
  text-align: center;
  cursor: pointer;
}

/* Timeline Content */
.timeline-content {
  font-size: 14px;
}

/* Timeline Title */
.timeline-title {
  margin: 0;
  font-size: 18px;
  color: #5C7AFF;
}

/* Timeline Date */
.timeline-date {
  margin: 0;
  font-size: 16px;
  color: #17C3B2;
}

/* Timeline Description */
.timeline-description {
  margin: 10px 0 0;
  font-size: 12px;
  color: #17C3B2;
}

/* Timeline Line */
.timeline:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: calc(100% - 30px); /* Adjust to account for marker margins */
  height: 2px;
  background: #ddd;
  z-index: 0;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .timeline-item {
    margin: 0 10px; /* Adjust margin for smaller screens */
  }
}
