.footer {
  background-color: #0B132B;
  color: #FFFFFF;
  padding-top: 10px;
  padding-left: 20px;
  display: flex;
  border-top: 4px solid #17C3B2;
  box-sizing: border-box;
}

.footer-info {
  justify-content: right;
  width: 100%;
}
.footer-info p {
  font-size: 0.9em;
  color: #FFFFFF;
}
