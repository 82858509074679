.mobile-timeline-container {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 0px 20px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .mobile-timeline-item {
    position: relative;
    margin-bottom: 30px; /* Spacing between items */
    padding-left: 30px; /* Space for the vertical line */
  }
  
  .mobile-timeline-marker {
    width: 50px;
    height: 50px;
    background: #0B132B;
    border-radius: 50%;
    position: absolute;
    left: -25px;
    top: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 2px #fff;
  }
  
  .mobile-timeline-marker img {
    width: 24px;
    height: 16px;
  }
  
  .mobile-timeline-content {
    padding: 10px;
    background: #0B132B;
    border-radius: 6px;
    color: #fff;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  
  .mobile-timeline-title {
    margin: 0;
    font-size: 14px;
    color: #5C7AFF;
  }
  
  .mobile-timeline-date {
    margin: 5px 0;
    font-size: 12px;
    color: #17C3B2;
  }
  
  .mobile-timeline-description {
    font-size: 14px;
  }
  
  .mobile-timeline-container:before {
    content: '';
    position: absolute;
    left: 25px;
    top: 0;
    width: 2px;
    height: 100%;
    background: #ddd;
    z-index: 0;
  }
  
  