
.card {
  background-color: #0B132B !important;
  border-radius: 15px !important; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  position: relative; /* For absolute positioning of the icon */
}

/* Card Icon */
.card-icon {
  width: 60px;
  height: 60px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -15px; /* Position the icon */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Adds a shadow to the icon */
  font-size: 25px;
  border: solid 1px #fff;
}

/* Card Content */
.card-content {
  text-align: right;
}

.card-content h4 {
  margin-right: 20px;
  font-size: 18px;
  color: #17C3B2;
}

.card-content h2 {
  margin-right: 20px;
  margin-top: 5px;
  font-size: 48px;
  color: #5C7AFF;
  font-family: Arial, Helvetica, sans-serif;
}

.card-content p {
  margin: 5px 0 0;
  font-size: 14px;
}

/* Summary Card */
.summary-card {
  margin: 10px;
  width: 300px;
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
  .summary-card {
      width: 250px; /* Adjust card width for tablets */
  }

  .card-content h2 {
      font-size: 36px; /* Reduce font size on smaller screens */
  }

  .card-content h4 {
      font-size: 16px; /* Adjust font size */
  }

  .card-icon {
      width: 50px;
      height: 50px;
      font-size: 20px; /* Scale down the icon */
  }
}

@media (max-width: 768px) {
  .summary-card {
      width: 200px; /* Further adjust width for smaller tablets */
  }

  .card-content h2 {
      font-size: 32px; /* Reduce font size for better fit */
  }

  .card-content h4 {
      font-size: 15px; /* Adjust font size */
  }

  .card-icon {
      width: 45px;
      height: 45px;
      top: -10px; /* Adjust positioning */
  }
}

@media (max-width: 480px) {
  .summary-card {
      width: 100%; /* Make cards full-width on small screens */
      margin: 10px 0; /* Adjust margin */
  }

  .card {
      padding: 15px; /* Adjust padding for small screens */
  }

  .card-content h2 {
      font-size: 28px; /* Further reduce font size */
      margin-right: 10px; /* Adjust margin */
  }

  .card-content h4 {
      font-size: 14px; /* Adjust font size */
      margin-right: 10px; /* Adjust margin */
  }

  .card-icon {
      width: 40px;
      height: 40px;
      top: -8px; /* Adjust positioning */
      font-size: 18px; /* Further reduce icon size */
  }
}
