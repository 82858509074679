.interests-hobbies-section {
    background-color: #F0F4F8;
    padding: 50px 20px;
    border-radius: 10px;
    margin-top: 50px;
  }
  
  .section-header h3 {
    color: #5C7AFF;
    font-size: 2.5em;
    text-align: center;
    margin-bottom: 40px;
    font-family: 'Verdana', sans-serif;
  }
  
  .hobbies-grid {
    display: flex;
    justify-content: center;
    gap: 40px;
    flex-wrap: wrap;
  }
  
  .hobby-item {
    background-color: #0B132B;
    padding: 20px;
    width: 200px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .hobby-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  .hobby-icon {
    font-size: 2.5em;
    color: #17C3B2;
    margin-bottom: 15px;
  }
  
  .hobby-item h4 {
    font-size: 1.5em;
    color: #5C7AFF;
    margin-bottom: 10px;
    font-family: 'Verdana', sans-serif;
  }
  
  .hobby-item p {
    font-size: 1em;
    color: #17C3B2;
    font-family: 'Verdana', sans-serif;
  }
  
