
.timeline-container {
  display: flex;
  overflow-x: auto; 
  padding: 20px;
  border-radius: 8px;
  padding-bottom: 110px;
  width: 100%; 
  box-sizing: border-box; 
}

.timeline {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%; 
}

.timeline-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1; 
  margin: 0 15px; 
}

.timeline-marker {
  width: 60px;
  height: 60px;
  background: #0B132B;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 2px #fff;
  position: relative;
  z-index: 1;
}

.timeline-marker img {
  width: 34px; 
  height: 24px; 
}


.timeline-tooltip {
  position: absolute;
  top: 120%; 
  left: 50%;
  transform: translateX(-50%);
  display: block;
  background: #0B132B;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  z-index: 2;
  width: 250px;
  text-align: center;
}

.timeline-content {
  font-size: 14px;
}

.timeline-title {
  margin: 0;
  font-size: 20px;
  color: #5C7AFF;
}

.timeline-date {
  margin: 0;
  font-size: 18px;
  color: #17C3B2;
}

.timeline-description {
  margin: 10px 0 0;
  font-size: 14px;
  color: #17C3B2;
}

.timeline:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: calc(100% - 30px);
  height: 2px;
  background: #ddd;
  z-index: 0;
}






