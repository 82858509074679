/* Mobile Certifications Container */
.mobile-certifications-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px; /* Adjust spacing for mobile view */
    padding: 15px 10px;
  }
  
  /* Mobile Certification Item */
  .mobile-certification-item {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%; 
    cursor: pointer;
    overflow: hidden;
    transition: ease-in-out;
    position: relative;
  }
  
  /* Expanded State for Mobile */
  .mobile-certification-item.expanded {
    max-height: 100%;
  }
  
  /* Mobile Certification Header */
  .mobile-certification-header {
    padding: 15px 15px 0px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #0B132B;
    color: #5C7AFF;
    font-size: 1rem; /* Adjust font size for mobile */
  }
  
  /* Expand Icon for Mobile */
  .mobile-certification-header .expand-icon {
    font-size: 1.2rem;
    padding: 0px 10px 15px 5px;
  }
  
  /* Mobile Certification Details */
  .mobile-certification-details {
    background-color: #e9e9e9;
    padding: 15px;
    color: #0B132B;
  }
  
  .mobile-certification-details p {
    margin-bottom: 5px;
  }
  