/* Learning Timeline */
.learning-timeline {
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
  margin: 0 auto;
  padding: 0 20px;
}

.learning-timeline-item {
  display: flex;
  align-items: center;
}

.learning-timeline-icon {
  background-color: #0b132b;
  color: #5c7aff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  font-size: 1.5em;
}

.learning-timeline-content {
  background-color: #0b132b;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  transition: transform 0.3s, box-shadow 0.3s;
  width: 100%;
}

.learning-timeline-content h4 {
  font-size: 1.2em;
  color: #5c7aff;
  font-family: 'Verdana', sans-serif;
}

.learning-timeline-content p {
  margin: 0;
  color: #d6d6d6;
  font-family: 'Verdana', sans-serif;
  font-size: 1rem;
}

.learning-timeline-item:hover .learning-timeline-content {
  transform: translateX(10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

/* Future Goal Section */
.future-goal {
  margin-top: 40px;
  text-align: center;
}

.future-goal h3 {
  color: #0b132b;
  font-size: 1.75em;
  margin-bottom: 20px;
  font-family: 'Verdana', sans-serif;
}

.future-goal-content {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  margin: 0 auto;
  background-color: #0b132b;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.future-goal-icon {
  font-size: 2.5em;
  color: #5c7aff;
  margin-right: 20px;
}

.future-goal-content p {
  color: #d6d6d6;
  font-size: 1em;
  font-family: 'Verdana', sans-serif;
}

.future-goal-content:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

/* Responsive Design for Smaller Screens */
@media (max-width: 768px) {
  .learning-timeline {
    flex-direction: column;
  }

  .learning-timeline-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .learning-timeline-icon {
    margin-bottom: 10px;
  }

  .future-goal-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .future-goal-icon {
    margin-bottom: 20px;
  }
}
